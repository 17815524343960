import '@fontsource/pt-sans/700.css';
import '@fontsource/pt-sans/400.css';
import '@fontsource/open-sans/400.css'
import {extendTheme} from "@chakra-ui/react"

const ChakraTheme = extendTheme({
    fonts: {
        heading: "PT Sans, sans-serif",
        body: "Open Sans, sans-serif",
    }
})

export default ChakraTheme;
